.footer {
  background: #1ba3ad;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.12);
  box-sizing: border-box;
  width: 100%;
  text-align: left;
  position: fixed;
  bottom: 0;
  margin-top: 100px;
}
.m-l-8 {
  margin-left: 8px;
}
/* Footer links */

.footer .footer-links {
  color: #ffffff;
  margin: 5px 0 5px;
  padding: 1px;
}

.footer .footer-links a {
  display: inline-block;

  text-decoration: none;
  color: inherit;
}
