@import "../../styles/usfAppTheme.scss";
.doc-box {
  max-width: 100%;
  padding: 16px;
}

.document-modal-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: $ModalBackgroundColor;
  padding: 20px;
  outline: none;
}
