body{
  position: relative;
  min-height: 100%;
  padding-bottom: 3.74rem;
}
div {
  scrollbar-width: thin;
}
.loan-section {
  background: #f9f9f9;
  padding: 8px;
  margin: 0px 8px;
}

.card-section {
  margin: 8px;
}

.pointer {
  cursor: pointer;
}
.my-8 {
  margin: 8px 0px;
}
.flex-start {
  justify-content: flex-start;
  align-items: center;
}

.mr-8 {
  margin-right: 8px;
}
.m-16 {
  margin: 16px;
}
.modal-divider {
  background-color: #1BA3AD;
}

.pad-5 {
  padding: 25px;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-t-8 {
  margin-top: 8px;
}

.m-t-16 {
  margin-top: 16px;
}

.m-t-20 {
  margin-top: 20px;
}

.m-r-8 {
  margin-right: 8px;
}

.span-text-color {
  color: #1BA3AD;
}

.small-modal-style {
  position: absolute;
  top: 45%;
  left: 50%;
  color: #1BA3AD;
  transform: translate(-50%, -50%);
  width: 500px;
  height: 200px;
  background-color: white;
  box-shadow: 24;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding: 25px;
  padding-top: 30px;
}

.amplify-button--primary{
      background-color: #1BA3AD !important;
}
.amplify-button--primary:hover{
      background-color: #1BA3AD !important;
}

.MuiStepIcon-root, .MuiLink-root, .primary-one{
  color: #1BA3AD !important;
  .MuiTab-root.Mui-selected {
color: #1BA3AD !important;
  }
}
 .MuiButton-contained{
  background-color: #1BA3AD !important;
}
.MuiDataGrid-columnHeaderTitle {
  font-family: "Economica" !important;
  font-weight: bold !important;
  font-size: 13px !important;
}
.sidebar-container {
  span {
    font-family: "Economica";
    font-size: 13px;
  }

  width: 100%;
}
.amplify-input {
  background-color: #ECF7F7 !important;
}

@media screen and (max-device-width: 480px) and (orientation: portrait) {
  // :root {
  //   --amplify-components-authenticator-modal-width: 90%;
  // }
}