@import "../../styles/usfAppTheme.scss";
.bg-image {
  background-image: url("../../../public/Assets/FINAL-SITE-44.png");
  background-position: center 26%;
  background-repeat: no-repeat;
  background-size: cover;
  color: $TextColorWhite;
}

.card-style {
  height: 310px;
  padding: 18px;
}
.box-info {
  padding-top: 55px;
  padding-left: 25px;
  padding-bottom: 10px;
  min-height: 250px;
}

.divider-style {
  background-color: #1BA3AD;
  height: 0.8px;
}

.border-style {
  border: 1px solid silver;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-10 {
  margin-bottom: 10px;
}

.m-25 {
  margin: 25px;
}

.pad-8 {
  padding: 8px;
}
.pad-10 {
  padding: 10px;
}

.pad-16 {
  padding: 16px;
}

.pad-40 {
  padding: 40px;
}

.mt-24 {
  margin-top: 24px;
}

.pad-t-l-r-25 {
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
}

.step-label {
  margin-top: -8px;
  margin-bottom: -8px;
}

.msg-row:hover {
  background-color: #f2f3f3;
}

.align-end {
  justify-content: end;
  align-items: center;
}

.align-center {
  justify-content: center;
  align-items: center;
}
.color-primary {
  color: $colorPrimary;
}

.color-white {
  color: #e3ecee;
}

.color-pink {
  color: #a6315c;
}

.color-blue {
  color: #00889f;
}

.modal-style {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 60%;
  background-color: $ModalBackgroundColor;
  padding: 20px;
  outline: none;
}

.flex-start {
  justify-content: flex-start;
  align-items: center;
}

.scrollbar {
  overflow: auto;
}

.center {
  text-align: center;
}

.disable-section {
  pointer-events: none;
  opacity: 0.4;
}

.done-icon {
  color: lightgreen;
  height: 18px;
}

.close-icon {
  color: red;
  height: 18px;
}

.link-color {
  color: #1BA3AD;
}

.loader-position {
  position: absolute;
  top: 50%;
  left: 50%;
}

.password-match-text {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  color: red;
}

.textfield-style {
  width: 100%;
  border: 1px lightgrey solid;
}

.textfield-container {
  margin-top: 8px;
}

.checkbox-style {
  height: 8px;
  color: lightgrey;
}

.input-lables {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
}

.f-14 {
  font-size: 14px;
}

.f-12 {
  font-size: 12px;
}