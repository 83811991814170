.search-text-field {
  width: 94%;
}

.search-btn {
  border-radius: unset;
  height: 35px;
}

.divider {
  margin-top: 5px;
}

.search-text-field>input {
  height: 2px !important;
}

.search-text-block {
  padding: 5px 0px 0px 0px;
}

.search-pat-btn {
  padding: 8px;
  background: #1BA3AD !important;
  color: white !important;
  border: 0;
  height: 38px;
  width: 47px;
  min-width: unset !important;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.circle-icon {
  margin-bottom: -5px;
  color: #1976d2;
}

.clinical-details-section {
  padding: 10px;
  background-color: $BackgroundWhiteColor;
}

.modal-stack {
  margin-top: 10px;
  align-items: $AlignItemsCenter;
  justify-content: $JustifyContentSpaceBetween;
}

.pad-10 {
  padding: 10px;
}

.pad-5 {
  padding: 5px;
}

.m-10 {
  margin: 10px;
}

.patient-dropdown {
  width: 90%;
  height: 35px;
}

.align-center {
  align-items: $AlignItemsCenter;
}

.m-l-40 {
  margin-left: 40px;
}

.m-l-20 {
  margin-left: 20px;
}

.grid-style {
  height: 400px;
  width: 100%;
  padding-top: 10px;
}

.grid-header {
  align-items: $AlignItemsCenter;
  justify-content: $JustifyContentSpaceBetween;
}

.header {
  align-content: center;
  margin: 10px !important;
  font-weight: bold;

}

.m-t-5 {
  margin-top: 5px !important;
}

.m-t-10 {
  margin-top: 10px !important;
}

.m-l-10 {
  margin-left: 10px !important;
}

.down-arrow {
  margin-top: -3px;
  margin-left: 5px;
}

.teal-bg-color {
  background-color: $BackgroundTealGrayColor;
}

.mail-Icon {
  width: 9px;
  position: relative;
  top: 8px;
  left: 5px;
}

.m-r-m15 {
  margin-right: -15px !important;
}

.m-r-m10 {
  margin-right: -10px !important;
}

.m-t-m10 {
  margin-top: -10px !important;
}

.display-none {
  display: none !important;
}

.directory-container {
  position: relative;
}

.patient-context {
  min-width: 310px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  background: #ffffff;
}

a {
  color: #1BA3AD;
}

.pay {
  margin-top: 10px;
  margin-bottom: 5px;
}

.grid-item {
  margin-left: 48px;
  margin-bottom: 20px;
}

.grid-loan {
  border: 1px black solid;
  display: block;
  color: #1BA3AD;
  width: 90%;
  padding: 10px;
  margin-top: 1;
  margin-left: 48px;
  margin-bottom: 20px;
}

.icon-button {
  margin: 1;
  float: right;
}

.row {
  display: flex;
  flex: 1 0 100%;
  flex-wrap: wrap;
  margin: 20px;
  align-items: center;
  color: black;
}

.col-3 {
  flex: 0 0 auto;
  width: 33.333333%;

}

.col-4 {
  flex: 0 0 auto;
  width: 25%;

}

.grid-container {
  grid-row-gap: 15px;
}

.grid-const {
  display: inline;
  width: 100%;
  padding: 10px;
}

.icon {
  margin: 1;
  margin-right: 5px;
  float: left;
}

.pay-head-text {
  flex-direction: row !important;
}



@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .pay-head-text {
    flex-direction: column !important;
    margin-bottom: 3px !important;
  }
}