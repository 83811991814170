$TextColorWhite: #fff;
$ModalBackgroundColor: #fff;
$BackgroundWhiteColor: #fff;
$GridHeight: 400px;
$GridWidth: 100%;
$AlignItemsCenter: center;
$TextAlignCenter: center;
$JustifyContentSpaceBetween: space-between;
$TextDecorationNone: none;
$BackgroundTealGrayColor: #f9f9f9;
$colorPrimary: #1BA3AD;
$HeaderBgColor: #1BA3AD;

@import url('https://fonts.googleapis.com/css2?family=Economica:ital,wght@0,400;0,700;1,400;1,700&display=swap')
