@import "../../styles/usfAppTheme.scss";

.mt-8 {
  margin-top: 8px;
}

.p-8 {
  padding: 8px;
}

.mx-8 {
  margin: 0px 8px;
}

.p-4 {
  padding: 4px !important;
}

.p-0 {
  padding: 0px !important;
}

.p-1 {
  padding: 1px !important;
}

.pad-b-0 {
  padding-bottom: 0px !important;
}

.mt-16 {
  margin-top: 16px;
}

.add-to-practice {
  background: #f9f9f9;
  padding: 15px 0px;
  margin-top: 16px;
}

.h-divider {
  background: "#1BA3AD" !important;
  margin: 8px 0px !important;
  height: 1px !important;
}

.remove-bottom-padding {
  padding: 8px !important;
  padding-bottom: 8px !important;
}

.flex-start {
  align-items: "center";
  justify-content: "space-between";
}

.border-bottom {
  border-bottom: 7px solid #818294 !important;
  position: relative;
}

.bubble-point {
  height: 10px;
  width: 10px;
  left: 50%;
  background: #818294;
  position: absolute;
  bottom: -3.5%;
  transform: rotate(45deg);
}

.fap-icon {
  height: 50px;
  width: 252px;
}

.estimate-details {
  background: #f9f9f9;
  margin: 0px 8px;
}

.effective-text {
  padding-left: 60px;
  padding-right: 60px;
  text-align: center;
  font-size: 18px !important;
  line-height: 21px !important;
  color: #ffffff !important;
}

.card-border {
  border: 2px solid #00889f !important;
}

.card-border-new {
  border: 2px solid #3A4356 !important;
}

.card-border-0 {
  border: 0px solid #00889f !important;
}

.min-h-103 {
  min-height: 103px !important;
}

.link-text {
  font-size: 14px !important;
  line-height: 16px !important;
}

.program-summary {
  background: #facfda;
  padding: 10px;
  color: #a6315c;
}

.bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #efefef;
  min-height: 180px;
}

.bar-box {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  margin: 1px;
  padding: 1px;
  text-align: center;
  height: 80px;
  background: #b9e0e9;
  font-size: 11px !important;
  color: #00889f;
  align-self: flex-end;
}

.bar-container-box {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  margin: 2px;
  text-align: center;
  height: 100px;
  font-size: 12px !important;
  align-self: flex-end;
}

.compare-details {
  margin-top: 32px !important;
  padding: 10px;
  background: #a6315c;
  color: #ffffff;
  font-size: 14px;
}

.compare-details-items {
  padding: 20px;
  background: #efefef;
}

.align-center {
  justify-items: center;
  align-items: center;
}

.program-summary-container {
  min-height: 190px !important;
}

.mt-42 {
  margin-top: 90px !important;
}

.bt-r-0 {
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

.m-b-10 {
  margin-bottom: 10px !important;
}

.program-btn {
  text-transform: uppercase !important;
  margin-top: 5px !important;
  border-radius: 20px !important;
}

.modal-stack {
  margin-top: 10px;
  align-items: $AlignItemsCenter;
  justify-content: space-between;
}

.alert-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 450px;
  background-color: $ModalBackgroundColor;
  padding: 10px;
}

.refund-option-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
  background-color: $ModalBackgroundColor;
  padding: 10px;
}

.bar-direction {
  flex-direction: column;
}

.bar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #efefef;
  min-height: 180px;
}

.bar-direction {
  flex-direction: column;
}

.alignSelf {
  align-self: center;
}

.height-50 {
  height: 50px !important;
}

.theme-color {
  color: #1BA3AD;
}

.align-right {
  justify-content: flex-end;
  align-items: center;
}

.space-between {
  justify-content: space-between;
  align-items: center;
}

.total-cost {
  background: #f1f1f1;
  margin: 8px 0px;
  padding: 8px 0px;
  border-top: 1px solid grey !important;
  border-bottom: 1px solid grey !important;
}

.disabled-box {
  color: rgb(0 0 0 / 26%);
  background: rgb(0 0 0 / 20%);
  cursor: default;
}


@media screen and (max-device-width: 480px) and (orientation: portrait) {
  .alert-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    background-color: $ModalBackgroundColor;
    padding: 10px;
  }

  .bar-container-box {
    margin-left: 6px;
    margin-right: 6px;
  }
  .bar-box {
    width: 70px;
  }
}