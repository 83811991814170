$ModalBackgroundColor: #fff;
$AlignItemsCenter: center;
.message-header {
  margin: 16px;
}
.flex-between {
  justify-content: space-between;
  align-items: center;
}

.font-24 {
  font-size: 24px !important;
}
.static-text {
  background: #f9f9f9;
  margin: 8px 0px;
}
.p-4 {
  padding: 4px;
}

.grid-style {
  height: 400px;
  width: 100%;
  padding-top: 10px;
}

.message-body {
  margin: 1cm;
  width: 1000px;
  padding: 20px;
  border: 1px solid #ccc;
  border-top: 4px solid skyblue !important;
  border-radius: 5px;
}

.replied-message-style {
  width: 800px;
  padding: 25px;
  padding-top: 0px !important;
}

.textarea-style {
  margin-top: 20px;
  width: 51em;
}

.ql-editor {
  margin-left: -15px;
  min-height: 18em;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}

.alert-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: $ModalBackgroundColor;
  padding: 20px;
}

.modal-divider {
  background-color: #1BA3AD;
}

.modal-stack {
  margin-top: 10px;
  align-items: $AlignItemsCenter;
  justify-content: space-between;
}

.modal-bottom-section {
  align-items: $AlignItemsCenter;
  justify-content: flex-end;
  margin-top: 20px;
}

.compose-message-heading {
  margin: 25px;
  justify-content: space-between;
  align-items: center;
}

.compose-static-text {
  background: #f9f9f9;
  margin-top: 40px;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 6px solid #e3e3e3;
  border-top: 6px solid #3498db;
  position: fixed;
  left: 50%;
  top: 50%;
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}

.msg-attachment {
  margin-top: 1cm;
  padding: 2px;
  width: 200px;
  height: 130px;
  overflow: hidden;
}

.msg-attachment:hover {
  background-color: #e3e3e3;
}

.cardmedia {
  position: absolute;
  flex-grow: 1;
}

.word-wrap {
  white-space: break-spaces !important ;
}


@media screen and (max-device-width: 480px) and (orientation: portrait){
  .alert-modal {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      background-color: $ModalBackgroundColor;
      padding: 20px;
    }
}