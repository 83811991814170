.profile-header{
    color: #808080;
}

.profile-blue{
    color: #1BA3AD;
}

.horizontal-row{
    height: 2px;
    background: #1BA3AD;
    border: none;
    margin: 5px 0 !important;
    width: 80%;
}

.location-stack{
    padding: 8px 8px 0 8px;
}

.location-name{
    background-color: #fffee7;
    padding: 2px;
    width: 80%;
}

.primary-location{
    color:#ffcc00;
}

.toll-free{
    color:black !important;
}

.mt-10{
    margin-top: 10px !important;
}
.mt-15{
    margin-top: 15px !important;
}
