@import "../../styles/usfAppTheme.scss";
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
.app {
  position: relative;
  max-width: 1920px;
  min-width: 1023px;
  height: 100vh;
  margin: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
}
a {
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  color: $TextColorWhite;
}
.nav-global {
  display: flex;
  background-color: #1BA3AD;
  height: 85px;
  padding-left: 16px;
  padding-right: 16px;
  align-items: center;
}

.nav-item {
  border-bottom: 5px solid transparent;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-align: center;
  margin-left: 16px;
  margin-right: 16px;
  padding: 11px 0px;
}
.width-min-content {
  width: min-content;
  height: 100%;
}
.width-60 {
  width: 60px;
  height: 100%;
}
.active {
  border-bottom: 4px solid #ee2557;
}
.center {
  text-align: center;
}
